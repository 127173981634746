import crudMutations from "@/store/templates/crud/mutations";

export default {
  setSubject(state, subject) {
    state.model.subject = subject;
  },
  setPrice(state, price) {
    state.model.price = price;
  },
  setCountries(state, countries) {
    state.model.countries = countries;
  },
  ...crudMutations
};
